<template>
  <div>
    <TopicsFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />

    <div class="main-content minHieght80 rounded-t-lg">
      <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
      <div v-else-if="!isLoading && isEmpty(topicList)">
        <NoRecordFound />
      </div>
      <TableWrapper v-else>
        <THead>
          <TRHead>
            <TH
              v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
              v-show="
                showColumnInDataTable(filtersData, head.dataTableName, ['Title', 'Topic Number'])
              "
              :key="head.id"
            >
              <span v-i18n="dashboard">
                {{ head.dataTableName }}
              </span>
            </TH>
            <TH v-permission="tableActionsPermissions" v-i18n="dashboard">Actions</TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(topic, index) in topicList" :key="index">
            <TD v-show="showColumnInDataTable(filtersData, 'Title', ['Title'])">
              {{ topic.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Topic Number', ['Topic Number'])">
              {{ topic.number || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Description')">
              {{ topic.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Subject')">
              {{ topic.chapter.subject.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Chapter')">
              {{ topic.chapter.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD
              v-show="showColumnInDataTable(filtersData, 'Actions', ['Actions'])"
              v-permission="tableActionsPermissions"
            >
              <TableAction
                :current-user="topic"
                :idx="index"
                :action-list="actionList"
                @action="topicAction"
              />
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>
      <Pagination
        v-if="showPagination"
        v-show="!isLoading"
        :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
        :max-range="paginationCounts(topicCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
        @filterRecord="getSubjectTopics"
      />
    </div>
    <TopicsModal
      v-if="showModal"
      :modal="showModal"
      :edit="currentRecord"
      @toggle="openOrCloseModal"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import Loader from '@components/BaseComponent/Loader.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import fileMixins from '@/src/mixins/file-mixins'
import TableAction from '@/src/components/TableAction.vue'
import { mapActions, mapState } from 'vuex'
import generalMixins from '@src/mixins/general-mixins.js'
import TopicsModal from '@/src/router/views/topics/topics-modal.vue'
import TopicsFilter from '@views/topics/TopicsFilter.vue'
import {
  removeEmptyKeysFromObject,
  objectDeepCopy,
  showColumnInDataTable,
} from '@utils/generalUtil'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import TopicsData from '@/src/router/views/topics/Topics.json'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  name: 'Topic',
  components: {
    NoRecordFound,
    Loader,
    TableAction,
    TopicsModal,
    Pagination,
    TopicsFilter,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixins, fileMixins, scrollMixin],
  data() {
    return {
      tableActionsPermissions: {
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
        roles: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.SECTION_TEACHER],
      },
      GENERAL_CONSTANTS,
      attendanceTranslation: 'attendanceTranslation',
      title: 'title',
      dashboard: 'dashboard',
      isLoading: false,
      noRecord: true,
      actionList: [{ name: 'Edit' }],
      currentRecord: null,
      selectedSubject: '',
      chaptersList: [],
      topicList: [],
      topicCounts: '',
      showPagination: false,
      tableHeaders: TopicsData.tableHeaders,
      defaultSelectedColumns: TopicsData.defaultSelectedColumns,
      filtersData: {},
    }
  },
  computed: {
    ...mapState({
      tabRightBar: (state) => state.layout.tabRightNav,
      showModal: (state) => state.layout.showModal,
      sectionId: (state) => state.layout.currentSectionScope.id,
      campusId: (state) => state.layout.currentCampusScope.id,
    }),
    isCampusOrSectionChange() {
      return this.sectionId + this.campusId
    },
  },
  page: {
    title: 'Syllabus | Topic',
    meta: [
      {
        name: 'description',
        content: 'Manage  Syllabus of each Topic ',
      },
    ],
  },
  watch: {
    isCampusOrSectionChange: {
      handler() {
        this.setRightbarData()
        this.getSubjectTopics()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchTopicStats()
      },
    },
  },
  mounted() {
    this.getSubjectTopics()
    this.setRightbarData()
  },
  methods: {
    isEmpty,
    showColumnInDataTable,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.getSubjectTopics()
    },

    getSubjectTopics(range) {
      this.isLoading = true
      let paginationRange = paginationRangeHandler(range)

      let payload = {
        ...paginationRange,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      removeEmptyKeysFromObject(payload)
      this.getTopics(payload).then((res) => {
        this.topicList = res.records
        if (!isEmpty(this.topicList)) {
          this.isLoading = false
          this.noRecord = false
          this.topicCounts = res.meta.total_records
          this.showPagination = this.topicCounts > GENERAL_CONSTANTS.RECORD_LIMIT
        } else {
          this.topicList = []
          this.isLoading = false
          this.noRecord = true
        }
        this.fetchTopicStats()
      })
    },
    fetchTopicStats(payload = { $where: this.filtersData?.$where }) {
      this.getTopicStats(payload).then((res) => {
        let count = res?.meta?.total_topic_count
        this.setRightbarData(count)
      })
    },
    /**
     * Topic Action
     * @param {string} action
     * @param {object} current
     * @returns {void}
     * @description Edit current Topic on the basis of action
     */
    topicAction(action, current) {
      this.currentRecord = { ...current, subject_id: this.selectedSubject }
      if (action === 'Edit') {
        this.openOrCloseModal()
      } else if (action === 'Delete') {
        this._deactivateTopic()
      }
    },
    _deactivateTopic() {
      this.isLoading = true
      this.deactivateTopic(this.currentRecord.id).then((res) => {
        this.isLoading = false
        this.getSubjectTopics()
        this.currentRecord = null
      })
    },
    openOrCloseModal(payload = null) {
      this.setShowModal()
      if (payload) this.getSubjectTopics()
      if (!this.showModal) this.currentRecord = null
    },

    setRightbarData(count = GENERAL_CONSTANTS.EMPTY_RECORD) {
      const rightBarContent = {
        header: {
          title: 'Topics',
          buttons: [
            {
              title: 'Add Topic',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
              permissions: {
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.CAMPUS_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                ],
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              { text: 'TOTAL_TOPICS', value: count, tooltipDescription: 'Total No of Topics' },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('chapter', ['getTopics', 'getTopicStats', 'deactivateTopic']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>

<style lang="scss" scoped>
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
