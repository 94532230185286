<template>
  <ValidationObserver @submit="addAndUpdateTopichandler">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ modalHeading }}
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="currentSubject"
            title="Select Subject"
            :options="subjectList"
            label="title"
            placeholder="Select Subject"
            class="flex-1"
            reduce="id"
            rules="required"
            :search-through-api="true"
            @search="getSubjects"
          />

          <UiSingleSelect
            v-model="topic.chapter_id"
            title="CHAPTERS"
            :options="chapterList"
            label="title"
            class="flex-1"
            reduce="id"
            rules="required"
            :search-through-api="true"
            @search="getChaptersList"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiInput
            v-model="topic.title"
            type="text"
            name="Topic Title"
            title="Topic Title"
            label="Topic Title"
            placeholder="Topic Title"
            rules="required"
          />
          <UiInput
            v-model="topic.description"
            type="text"
            name="Description"
            title="Description"
            label="Description"
            placeholder="Description"
          />
        </InputFieldWrapper>

        <InputFieldWrapper>
          <UiInput
            v-model="topic.number"
            type="text"
            name="Topic Number"
            title="Topic Number"
            label="Topic Number"
            placeholder="Topic Number"
            rules="required|numeric"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
        <UIButton button="primary">
          <span v-if="isLoading">
            <Loader />
          </span>
          <span v-else>{{ saveBtnTitle }}</span>
        </UIButton>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import FILTER_KEYS from '@src/constants/filter-constants.js'
import Loader from '@components/BaseComponent/Loader.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapActions } from 'vuex'
import { buildWhereQuery } from '@utils/filters/index'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  name: 'TopicModal',
  components: {
    ValidationObserver,
    UIButton,
    UiModalContainer,
    UiInput,
    UiSingleSelect,
    Loader,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
    topicList: { type: Array, default: () => [] },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      placeholder: 'placeholder',
      isLoading: false,
      chapterList: [],
      currentSubject: '',
      topic: {
        title: '',
        description: '',
        number: null,
        chapter_id: '',
      },
      subjectList: [],
    }
  },
  computed: {
    saveBtnTitle() {
      return this.edit ? 'Update' : 'Create'
    },
    modalHeading() {
      return this.edit ? 'Edit Topic' : 'Add Topic'
    },
  },
  watch: {
    currentSubject: {
      handler(id) {
        this.chapterList = []
        this.topic.chapter_id = ''
        if (id) this.getChaptersList()
      },
    },
  },
  created() {
    if (this.edit) this.setTopicData()
  },
  mounted() {
    this.getSubjects()
  },
  methods: {
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('chapter', ['addTopic']),
    ...mapActions('chapter', ['getChapters']),
    getSubjects(query) {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.classSubjects(payload).then((res) => {
        this.subjectList = res?.data?.records || []
        if (this.edit) {
          let isExist = this.subjectList.some((el) => el.id === this.edit.chapter.subject_id)
          if (!isExist) this.subjectList.unshift(this.edit.chapter.subject)
        }
      })
    },
    getChaptersList(query) {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', this.currentSubject),
        },
      }
      this.getChapters(payload).then((res) => {
        this.chapterList = res.records
        if (this.edit) {
          this.topic.chapter_id = this.edit?.chapter_id
          let isExist = this.chapterList.some((el) => el.id === this.edit.chapter.id)
          if (!isExist) this.chapterList.unshift(this.edit.chapter)
        }
      })
    },

    /**
     * Handle click
     * @param {string} eventName
     * @returns {void}
     * @description Calling setShowModal on the basis of event name
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    setTopicData() {
      this.currentSubject = this.edit.chapter.subject_id
      this.topic = {
        title: this.edit?.title,
        description: this.edit?.description,
        number: this.edit?.number,
        chapter_id: this.edit?.chapter_id,
      }
    },
    /**
     * Form Check
     * @param {boolean} invalid
     * @returns {void}
     * @description Method description
     * -  Add new Topics
     * -  Edit existing Topics
     * -  Show toast regarding to action we perform eg:Create or Edit
     */
    addAndUpdateTopichandler() {
      this.isLoading = true
      const data = {
        topic: this.topic,
      }
      if (this.edit) data.id = this.edit?.id
      this.addTopic(data)
        .then((res) => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
