<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="TOP"
      tooltip-title="TOP"
      :display-breadcrumb="true"
      :displayed-column-options="csvFilterOptions"
      :already-selected-displayed-column="filtersPayload.displayedColumns"
      :count="filtersCount"
      @cancel="cancelHanlder"
      @applyFilter="emitFiltersPayload($event, invalid)"
      @clear="clearFilters"
    >
      <template v-slot:filterItems>
        <InputFieldWrapper layout-mode="true">
          <UiInput
            v-model.trim="filtersPayload.title"
            class="flex-1"
            title="Title"
            type="text"
            placeholder="TITLE"
            :filter="FILTERS_LIST.TITLE"
            @emitQuery="updateQuery"
          />
          <UiInput
            v-model.trim="filtersPayload.number"
            class="flex-1"
            title="TOPIC_N"
            type="text"
            rules="numeric"
            placeholder="TOPIC_N"
            :filter="FILTERS_LIST.NUMBER"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-model.trim="filtersPayload.subject_id"
            title="Select Subject"
            :options="subjectList"
            label="title"
            placeholder="Select Subject"
            class="flex-1"
            :class="$style.widthItems"
            reduce="id"
            :filter="FILTERS_LIST.SUBJECT"
            :search-through-api="true"
            @emitQuery="updateQuery"
            @search="getSubjectList"
          />
          <!-- selectedChapter -->
          <UiSingleSelect
            v-model.trim="filtersPayload.chapter_id"
            title="SELECT_CHAPTER"
            :options="chaptersList"
            label="title"
            placeholder="SELECT_CHAPTER"
            :class="$style.widthItems"
            class="flex-1"
            reduce="id"
            :filter="FILTERS_LIST.CHAPTER"
            :search-through-api="true"
            @emitQuery="updateQuery"
            @search="_getChapters"
          />
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import TitleWrapper from '@src/components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { getCountFromObject, removeEmptyKeysFromObject } from '@utils/generalUtil'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { buildWhereQuery } from '@/src/utils/filters'
import fileMixins from '@/src/mixins/file-mixins'
import filterMixins from '@/src/mixins/filter-mixins.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    TitleWrapper,
    UiSingleSelect,
    InputFieldWrapper,
  },
  mixins: [fileMixins, filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FILTERS_LIST,
      FILTER_KEYS,
      $where: {},
      queries: {},
      subjectList: [],
      appliedQueries: {},
      chaptersList: [],
      filtersCount: 0,
      filtersPayload: {
        title: '',
        number: '',
        subject_id: '',
        chapter_id: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      campus: (state) => state.layout.currentCampusScope || null,
    }),
  },
  watch: {
    campus: {
      handler() {
        this.clearFilters()
      },
    },
    'filtersPayload.subject_id': {
      handler(value) {
        this.filtersPayload.chapter_id = ''
        this.chaptersList = []
        if (value) this._getChapters()
      },
    },
    currentSectionScope: {
      handler() {
        this.clearFilters()
        this.getSubjectList()
        this._getChapters()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.getSubjectList()
    this._getChapters()
  },
  methods: {
    _getChapters(query = '') {
      let filtersPayload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', this.filtersPayload.subject_id),
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getChapters(filtersPayload).then((res) => {
        this.chaptersList = res.records
      })
    },
    getSubjectList(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.classSubjects(payload).then((resp) => {
        this.subjectList = resp.data?.records || []
      })
    },
    getFiltersCount() {
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('chapter', ['getChapters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
